jQuery(document).ready(function () {
    let arrLocation = $('.js-location');
    if (arrLocation.length > 0 ){
        tryGeolocation();
    }
});

/*function getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(displayPosition);
    } else {
        $('.js-location').innerHTML = "Geolocation is not supported by this browser.";
    }
}*/

function tryGeolocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            browserGeolocationSuccess,
            browserGeolocationFail,
            {maximumAge: 50000, timeout: 20000, enableHighAccuracy: true});
    }
};

/*function displayPosition(position) {
    $('.js-location').innerHTML = "Latitude: " + position.coords.latitude +
        "<br>Longitude: " + position.coords.longitude;
}*/




function apiGeolocationSuccess(position) {
    $('.js-location')[0].innerHTML = "Latitude: " + position.coords.latitude +
        "<br>Longitude: " + position.coords.longitude;
};

function tryAPIGeolocation() {
    jQuery.post( "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDYFZCg2K_Mt7mOEpP2beKBELZrrcmgqaE", function(success) {
        apiGeolocationSuccess({coords: {latitude: success.location.lat, longitude: success.location.lng}});
    })
        .fail(function(err) {
            alert("API Geolocation error! \n\n"+err);
        });
};

function browserGeolocationSuccess(position) {
    $('.js-location')[0].innerHTML = "Latitude: " + position.coords.latitude +
        "<br>Longitude: " + position.coords.longitude;
};

function browserGeolocationFail(error) {
    switch (error.code) {
        case error.TIMEOUT:
            alert("Browser geolocation error !\n\nTimeout.");
            break;
        case error.PERMISSION_DENIED:
                tryAPIGeolocation();
            break;
        case error.POSITION_UNAVAILABLE:
            alert("Browser geolocation error !\n\nPosition unavailable.");
            break;
    }
};
