


jQuery(document).ready(function () {
    $('.js-concurrent-toggle').change(function () {

        let concurrentId = $(this).data('concurrent');
        toggleConcurrent(concurrentId);
        checkMaximumConcurrents();
    })
    checkMaximumConcurrents();
});

function checkMaximumConcurrents(){
    let arrToggle = $('.js-concurrent-toggle')

    let intNbChecked = 0;
    for(let i in arrToggle){
        intNbChecked = intNbChecked+(arrToggle[i].checked?1:0);
    }
    if (intNbChecked >= 3){
        $(".js-concurrent-toggle:not(:checked)").bootstrapToggle('disable');
    }
    else{
        $('.js-concurrent-toggle').bootstrapToggle('enable')
    }
}

function toggleConcurrent(concurrentId) {
       let url = "/index.php/mapharma/switchconcurrent/"+concurrentId;

    $.ajax({
        url: url
    }).done(function( data ) {})
    .fail(function( data ) {
        //Erreur, on remet le toggle dans sa position initiale
        console.log("err " + concurrentId);
        let toggle = $('.js-concurrent-toggle[data-concurrent="'+concurrentId+'"]')
        let value = toggle[0].checked;
        toggle.bootstrapToggle(value?'off':'on',true);
        checkMaximumConcurrents();
    })

}