var autocompleteOptions = {
    url: "/json/marques.json",

    getValue: "name",
    list: {
        maxNumberOfElements: 10,
        match: {
            enabled: true
        },

        onSelectItemEvent: function() {
            let id = $("#marque-search").getSelectedItemData().id;

            $("#marque-search").val(id);
        }
    }
};

jQuery(document).ready(function () {
    $("#marque-search").easyAutocomplete(autocompleteOptions);

    $(".js-filter-cb").change(function () {
        if ($(".js-filter-cb:checked").length == 0){
            $(this).prop( "checked", true );
            return;
        }
        let strPriceType = $(this).data('pricetype');
        toggleFilter(strPriceType);
        //calculateCA();
    })

    $('[data-toggle="tooltip"]').tooltip();
});


function toggleFilter(strPriceType) {
    //$(".js-filter-btn[data-pricetype='"+strPriceType+"']").toggleClass("btn-primary").toggleClass("btn-outline-primary");
    $("tr[data-pricetype='"+strPriceType+"']").toggleClass("d-none");

    //on cache les tableaux vides
    $('table.js-product-table').each( function( i, table ) {
        let displayedProducts = $('tr.js-product-display-line:not(.d-none)',table );
        if (displayedProducts.length > 0){
            $(table).removeClass("d-none");
            let arrCssClasses=["odd","odd","even","even"]
            displayedProducts.each(function( i, tr ) {
                $(tr).removeClass("odd").removeClass("even").addClass(arrCssClasses[i%4]);

            })
        }
        else{
            $(table).addClass("d-none");
        }
    });


}

function calculateCA() {

    let productTr, ca, predictedCA;
    let totalCA = 0, totalPredictedCA = 0;
    $('table.js-product-table tr.js-product-line:not(.d-none)').each( function( i, tr ) {
        productTr = $( tr );
        ca = parseFloat(productTr.data('ca'));
        predictedCA = parseFloat(productTr.data('predictedca'));

        totalCA+=ca;
        totalPredictedCA+=predictedCA;
    });

    let ratio = totalCA ==0?0:round(((totalPredictedCA - totalCA)/totalCA*100),1);

    $(".js-totalCA")[0].innerHTML = numberWithCommas(round(totalCA,2)) +" €";
    $(".js-totalPredictedCA")[0].innerHTML = numberWithCommas(round(totalPredictedCA,2)) +" €";
    $(".js-totalRatioChangeCA")[0].innerHTML = (ratio>0?"+ ":"")+ratio + " %";
}



function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}