/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require ('../css/bootstrap/bootstrap.css');
require('bootstrap4-toggle/css/bootstrap4-toggle.min.css');
require('../css/easy-autocomplete.css');
require('../css/easy-autocomplete.themes.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
//const $ = require('jquery');

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
require('bootstrap4-toggle/js/bootstrap4-toggle.min.js');
require('./jquery.easy-autocomplete.js');

require('../js/concurrentChooser.js');
require('../js/pharmaChooser.js');
require('../js/pricesScreen.js');
require('../js/comparator.js');

