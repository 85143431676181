


jQuery(document).ready(function () {
    $('.js-pharma-toggle').change(function () {
        let userId = $('#user_id')[0].value;
        let concurrentId = $(this).data('pharmacie');
        togglePharmacie(userId,concurrentId);
    })
});

function togglePharmacie(userId,concurrentId) {

    let url = "/index.php/link/switchpharma/"+userId+"/"+concurrentId;
    $.ajax({
        url: url
    }).done(function( data ) {})
    .fail(function( data ) {
        //Erreur, on remet le toggle dans sa position initiale
        console.log("err " + concurrentId);
        let toggle = $('.js-pharma-toggle[data-pharmacie="'+concurrentId+'"]')
        let value = toggle[0].checked;
        toggle.bootstrapToggle(value?'off':'on',true);
    })

}